import { useState, useEffect } from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import GamingAll from '../../../components/Gaming/All'
import { getListClassification } from '../../../api/gaming';
import HeadNavigation from '../../../store/HeadNavigation';
import { PlusOutlined } from '@ant-design/icons';
const GamingHomeView = () => {
    const navigate = useNavigate();
    const [activeNav, setActiveNav] = useState<string | number>(0);
    const [dictDataList, setDictDataList] = useState<any[]>([
        { id: 0, classification: 'All' },
        { id: 'myFavorites', classification: 'My favorites' },
    ]);
    // 点击切换
    const navigateClick = (id: string | number) => {
        setActiveNav(id);
    }
    // 获取导航类型
    const getListClassificationFn = async () => {
        try {
            const res = await getListClassification()
        setDictDataList([...dictDataList, ...res.data.data])
        } catch (error) {
            console.log(error)
        }
    }
    // 跳转
    const navigateHandle = () => {
        navigate(`/gaming/addOrEdit?type=add`)
    }
    // 在组件挂载后调用
    useEffect(() => {
        HeadNavigation.setItem({ backTo: '/home', goName: 'Home', currentName:'Games' });
        getListClassificationFn();
    }, []);
    return (
        <div className="w-full h-full flex">
            <div className="h-full flex flex-col justify-between  overflow-auto border-r border-[#D2D2D2] py-4 space-y-3 min-3">
                <div>
                    {dictDataList.map((item, index) => (
                        <div key={item.id}
                            className={`w-full h-16 border-r-4  hover:bg-[#F6F8F8] cursor-pointer flex items-center pl-16 space-x-2 min-5
                    ${activeNav === item.id ? ' border-[#275576] bg-[#F6F8F8] text-[#232323]' : 'border-white text-[#707070]'}`}
                            onClick={() => navigateClick(item.id)}>
                           
                            {
                                index > 7 ? (
                                    <img src={require(`../../../assets/images/gaming/8.svg`)} alt={item.index} />
                                ) : (
                                    <img src={require(`../../../assets/images/gaming/${index + 1}.svg`)} alt={item.index} />
                                )
                            }
                            <span className="text-xl font-normal min-4">{item.classification}</span>
                        </div>
                    ))}
                </div>
                <div className='w-full pb-4 px-6 min-4'>
                    <Button shape="round"
                        style={{ background: '#275576', borderColor: '#275576' }}
                        className='w-full h-11 flex items-center justify-center space-x-2'
                        onClick={navigateHandle}
                    >
                        <img src={require('../../../assets/images/public/send-01.png')} alt='send' />
                        <span className=' text-base font-medium text-white '> Submit new content</span>
                    </Button>
                </div>
                <div className='min-13'>
                    <Button color="default" onClick={navigateHandle} icon={<PlusOutlined />} style={{marginBottom: '10px'}} />
                </div>
            </div>
            <div className="flex-1 h-full mt-4 ml-7 pb-5 mr-16 overflow-auto min-6">
                {/* 主体内容 */}
                <GamingAll classificationId={activeNav} />
            </div>
        </div>
    )
}

export default GamingHomeView;

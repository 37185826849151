import { useState, useEffect } from 'react';
import { HeartOutlined } from '@ant-design/icons';
import ShareWith from '../../../components/Public/ShareWith';
import { getGameDetails } from "../../../api/gaming"
import { useLocation } from 'react-router-dom';
import HeadNavigation from '../../../store/HeadNavigation';
import Comments from '../../../components/Gaming/Comments';

// 详情
const Details = () => {
    const location = useLocation();
    // 详情数据
    const [itemDetails, setItemDetails] = useState<any>();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    // 获取详情
    const getGameDetailsFn = async () => {
        try {
            const res = await getGameDetails(Number(id))
            if (res.data.code === 200) {
                setItemDetails(res.data.data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getGameDetailsFn()
        HeadNavigation.setItem({ backTo: -1, goName:'Previous step', currentName: 'Games' });
    }, []);
    return (
        <div className="w-full h-full  overflow-auto px-14  flex justify-between space-x-5 ovh min-1-1">
            <div className="flex-1 h-full space-y-6 py-5">
                <div className="space-y-3">
                    <div className="flex items-center justify-between">
                        <div className=' flex items-center space-x-4'>
                            <span className="text-4xl font-medium text-[#232323] min-10">{itemDetails?.title}</span>
                            <div className={`mt-2 text-xl font-medium 
                                ${itemDetails?.collection || !itemDetails?.isShowHeard ? 'text-[#EA5E5E]' : 'text-[#000]'}`}>
                                <HeartOutlined />
                            </div>
                        </div>
                        <ShareWith />
                    </div>
                    <p className="text-xl font-normal text-[#707070]">
                        {itemDetails?.description}
                    </p>
                </div>
                <div className="w-full  rounded-md bg-[#232323] overflow-hidden">
                    <iframe
                        src={itemDetails?.content}
                        title={itemDetails?.title}
                        width="100%"
                        height="600px"
                        frameBorder="0"
                        scrolling="auto"
                    ></iframe>
                </div>
            </div>
            {/* 评论 */}
            <div className='h-full py-5 min-9'>
            <Comments id={itemDetails?.id} />
            </div>
        </div>
    )
}
export default Details
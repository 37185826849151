import { useEffect, useState } from 'react';
import SeelAll from '../../components/Global/SeelAll';
import { sysCommentPublic, activityInfoLastCount } from "../../api/user"
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs'
import TestimonialCard from './Testimonial/Card';
import TestimonialSeeAll from './Testimonial/SeeAll'
const LatestRelease = () => {
    const [sysCommentData, setsSysCommentData] = useState<any[]>([])
    const [activitiesArea, setActivitiesArea] = useState<any[]>([])
    const [testimonialSeeAllOpen, setTestimonialSeeAllOpen] = useState(false);
    const navigate = useNavigate();
    // 获取系统评论
    const sysCommentPublicFn = async () => {
        try {
            const res = await sysCommentPublic(4)
            if (res.data.code === 200 && res.data.data) {
                setsSysCommentData(res.data.data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    // 查询最近要开始的活动（按照开始时间排序）
    const activityInfoLastCountFn = async () => {
        try {
            const res = await activityInfoLastCount(2)
            if (res.data.code === 200 && res.data.data) {
                setActivitiesArea(res.data.data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    // 跳转到详情
    const navigationTypeJump = (id: number | string) => {
        navigate(`/eventsActivities/details?id=${id}`);
    }
    useEffect(() => {
        sysCommentPublicFn()
        activityInfoLastCountFn()
    }, []);

    return (
        <div className='w-[30%] border-t border-x border-[#D2D2D2] bg-[#FBFBFB] rounded-t-xl px-6 py-6 space-y-7 min-2'>
            <div className=' space-y-3'>
                <div className='flex justify-between items-center'>
                    <span className=' text-xl font-medium text-[#232323]'>Activities in your area</span>
                    <Link to='/eventsActivities/home'>
                        <div className='flex items-center text-base font-medium text-[#275576] space-x-2 cursor-pointer'>
                            <span>See all</span>
                            <SeelAll />
                        </div>
                    </Link>
                </div>
                <div className=' space-y-2'>
                    {activitiesArea.map((activitiesArea_item, activitiesArea_index) => (
                        <div className='flex items-center space-x-8 border-b border-[#D2D2D2] py-2 cursor-pointer' key={activitiesArea_index}
                            onClick={() => navigationTypeJump(activitiesArea_item.id)}
                        >
                            <div className='w-[6.25rem] h-16'>
                                <img src={activitiesArea_item.bannerPath} alt={activitiesArea_item.id} className='w-full h-full rounded-md  object-cover' />
                            </div>
                            <div className='flex-1'>
                                <div className='flex items-center space-x-8'>
                                    <div className=' flex items-center text-xl font-medium text-[#232323] space-x-1 u-line-1'>
                                        <span> {dayjs(activitiesArea_item.startTime).format('dddd')}</span>
                                        <span> @{dayjs(activitiesArea_item.startTime).format('h:mm a')}</span>
                                    </div>
                                    <div className=' text-base font-medium px-3 py-1 border border-[#DEDEDE] rounded-3xl u-line-1'>
                                        {dayjs(activitiesArea_item.startTime).format('MMM DD YYYY')}
                                    </div>
                                </div>
                                <div className=' flex-1 text-xl font-normal text-[#707070] u-line-1'>
                                    {activitiesArea_item.title}
                                </div>
                            </div>

                        </div>
                    ))}
                </div>
            </div>
            <div className=' space-y-3'>
                <div className='flex justify-between items-center'>
                    <span className=' text-xl font-medium text-[#232323]'>Testimonial</span>
                    <div className='flex items-center space-x-4'>
                        <div className='flex items-center text-base font-medium text-[#275576] space-x-2 cursor-pointer'
                            onClick={() => setTestimonialSeeAllOpen(true)}>
                            <span>See all</span>
                            <SeelAll />
                        </div>
                    </div>

                </div>
                <div className=' space-y-2'>
                    {sysCommentData.map((_item, _index) => (
                        <TestimonialCard item={_item} key={_index} />
                    ))}
                </div>
            </div>
            <TestimonialSeeAll visible={testimonialSeeAllOpen} onClose={() => {setTestimonialSeeAllOpen(false); sysCommentPublicFn()}} />
        </div>
    )
}
export default LatestRelease
import { Input, Spin } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import CardItem from './CardItem'; // 引入游戏卡片组件
import { getPageMyFavorites, getPagelmGame } from '../../api/gaming'; // 引入获取游戏数据的API

interface GamingAllProps {
    classificationId: any; // 游戏分类ID，可以是任意类型
}
// 游戏列表
const GamingAll: React.FC<GamingAllProps> = ({ classificationId }) => {
    const [searchValue, setSearchValue] = useState<string>(''); // 搜索关键词
    const [loading, setLoading] = useState<boolean>(false); // 加载状态
    const hasMore = useRef<boolean>(true); // 是否还有更多数据可加载
    const containerRef = useRef<HTMLDivElement>(null); // 容器的引用，用于监听滚动事件
    const currentPage = useRef<number>(1) //页码
    const dataList = useRef<any[]>([]) //游戏数据列表
    // 获取游戏信息的函数
    const getAllGameInformationFn = async (title?: string) => {
        console.log(  dataList.current ,'  dataList.current ')
        try {
            setLoading(true); // 开始加载数据
            // 根据分类ID调用不同的API获取数据
            const res = classificationId === 'myFavorites'
                ? await getPageMyFavorites({ title, type: 1, current: currentPage.current, size: 20 }) //我的收藏
                : await getPagelmGame({ title, classificationId, current: currentPage.current, size: 20 });

            if (res.data.code === 200 && res.data?.data.records) { // 如果请求成功
                dataList.current = [...dataList.current, ...res.data?.data.records]; // 更新游戏数据列表
                currentPage.current = currentPage.current + 1
                // 判断是否还有更多数据可加载
                if (res.data.data.total === dataList.current.length) {
                    hasMore.current = false; // 没有更多数据了
                }
            }
            setLoading(false); // 数据加载完成
        } catch (error) {
            console.log(error, 'error');
            setLoading(false); // 停止加载状态
        }
    };

    // 处理滚动事件
    const handleScroll = () => {
        if (!containerRef.current) return; // 如果容器不存在，直接返回
        const { scrollTop, clientHeight, scrollHeight } = containerRef.current; // 获取容器的滚动位置信息
        // 当滚动到底部且还有更多数据时，加载更多游戏信息
        if (scrollHeight - scrollTop === clientHeight && hasMore.current && currentPage.current!==1) {
            getAllGameInformationFn(); // 加载更多游戏信息
        }
    };
    // 重置
    const resetHandle = async () => {
        currentPage.current = 1; // 搜索时重置页码为1
        hasMore.current = true; // 重置是否有更多数据的状态
        dataList.current = []; // 清空当前游戏数据列表
    }
    // 处理搜索事件
    const handleSearch = () => {
        resetHandle()
        getAllGameInformationFn(searchValue); // 根据搜索关键词获取游戏信息
    };
    // 组件加载时获取数据
    useEffect(() => {
        const fetchData = async () => {
            await setSearchValue('')
            await resetHandle()
            getAllGameInformationFn(''); // 获取所有游戏信息
        };
        fetchData();
    }, [classificationId]); // 当分类ID变化时重新获取数据
    // 组件的渲染部分
    return (
        <div className='w-full h-full flex flex-col space-y-6 overflow-hidden'>
            <div className="flex justify-between items-center">
                <p className='text-2xl font-normal text-[#707070] min-7'>
                    Stay Sharp: Daily Brain Games and Exercises!
                </p>
                <Input
                    className='w-64 h-12 rounded-full bg-[#F1F1F1] min-4'
                    placeholder="Search games"
                    suffix={<SearchOutlined style={{ color: '#232323', fontSize: '1.25rem' }} />}
                    value={searchValue}
                    onPressEnter={handleSearch}
                    onChange={(e) => setSearchValue(e.target.value)}
                />
            </div>
            {/* 游戏信息展示区域 */}
            <div className='w-full flex-1 overflow-auto space-y-2' ref={containerRef} onScroll={handleScroll}>
                {/* 加载状态 */}
                    {dataList.current && dataList.current.length > 0 ? ( // 如果有游戏数据
                        // 游戏卡片列表
                        <div className="w-full flex-1 pb-5 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-5">
                            {dataList.current.map((item,index) => (
                                <CardItem
                                    item={item}
                                    key={index}
                                    onUpdate={handleSearch} // 更新游戏信息的回调函数
                                    isShowHeard={classificationId === 'myFavorites' ? true : false} // 是否显示收藏图标
                                />
                            ))}
                        </div>
                    ) : (
                        <div className="h-10 leading-10 text-center text-[#707070]">No data available</div> // 没有数据时显示的信息
                    )}
                 <div className='flex justify-center'><Spin spinning={loading} delay={500}></Spin></div>
            </div>
        </div>
    );
};

export default GamingAll; // 导出游戏展示组件

import { Button } from 'antd';
import { useEffect } from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import Footer from "../components/Footer"
import LogoSub from "../components/Global/LogoSub"
import { useNavigate } from 'react-router-dom';
import HomeCard from '../components/Home/Cardst';
import LatestRelease from '../components/Home/LatestRelease';
import indexStore from '../store/index'
import userStore from '../store/user';
import chartStore from '../store/chart'
import LoginInfo from '../components/Public/LoginInfo';
import { getUsersMyInfo } from '../api/user';
import { getLastMessageFn } from '../utils/readingMessages';

// 导航栏数据
const navigationData = [
    { title: 'Health & wellness', src: 'healthOrwellness', btnTitle: 'Let’s explore', path: '/healthOnline/home', type: 1 },
    { title: 'Event & activities', src: 'eventOrctivities', btnTitle: 'Participate', path: '/eventsActivities/home' },
    { title: 'Online classes', src: 'onlineClasses', btnTitle: 'Learn together', path: '/healthOnline/home', type: 2 },
    { title: 'My connection', src: 'myConnection', btnTitle: 'Build community', path: '/conversationalHub/home' },
    { title: 'Gaming', src: 'gaming', btnTitle: 'let’s have fun', path: '/gaming/home' },
]

// 首页
const Home = () => {
    const navigate = useNavigate();
    // 获取用户信息
    const getUsersMyInfoFn = async () => {
        const userRes = await getUsersMyInfo()
        // 获取用户信息
        if (userRes.data.code === 200) {
            userStore.setUserInfo({ ...userStore.state.userInfo, ...userRes.data.data })
        }
    }
    // 在组件挂载后调用
    useEffect(() => {
        // 菜单导航初始位置
        indexStore.clearData()
        if (userStore.state.token) {
            getUsersMyInfoFn()
            if (chartStore.chartsState.charts.length===0 && chartStore.chartsState.requestS.length===0) {
                getLastMessageFn()  //获取聊天未读消息记录
            }
        }
    }, []);


    return (
        <div className="w-full flex flex-col justify-between">
            <div className="flex justify-between items-center px-14 py-7 min-11">
                <LogoSub />
                {!userStore.state.token &&
                    <Button type="primary" shape="round"
                        iconPosition='end' icon={<ArrowRightOutlined />} className='flex items-center bg-[#EA5E5E] py-5 min-12'
                        onClick={() => navigate('/login')}
                    >
                        <span className=' text-base font-medium'> Start your journey</span>
                    </Button>
                }
                {userStore.state.token &&
                    <LoginInfo color='#275576' bgColor='white' />
                }
            </div>
            <div className="w-full flex-1 px-14  flex justify-between space-x-6 min-1">
                <div className='flex-1 mb-12 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-5'>
                    {navigationData.map((navigation_item, navigation_index) => (
                        <HomeCard navigationData_item={navigation_item} index={navigation_index} key={navigation_index} />
                    ))}
                </div>
                <LatestRelease />
            </div>
            <Footer />
        </div>
    )
}

export default Home

import { useState } from 'react';
import {  Button, Modal, message } from 'antd';
import { useEffect } from 'react';
import {  CloseOutlined, CheckOutlined } from '@ant-design/icons';
import chartStore from "../../store/chart"
import { friendsAgree, friendsRefuse, obtainNameBasedOnID } from "../../api/chat"
import { activityInfoToday } from "../../api/activity"
import dayjs from 'dayjs' // 导入插件
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
import HeadNavigation from '../../store/HeadNavigation';
import { observer } from 'mobx-react-lite'; // 使用 observer 包裹组件
import { saveLastMessageFn } from '../../utils/readingMessages';
import AvatarImg from '../../components/Public/AvatarImg';
// 消息通知
const Notification = observer(() => {
    const [articleType] = useState<any[]>([
        { dictCode: 'Activities', dictLabel: 'Activities' },
        { dictCode: 'FriendRequests', dictLabel: 'Friend requests' },
    ]);
    const [selectedTab, setSelectedTab] = useState<string | null | number>('Activities');
    const [requestSData, setRequestSData] = useState<any[]>([]);
    const [userInfoList, setUserInfoList] = useState<any[]>([]);
    const [activityList, setActivityList] = useState<any[]>([]);//今天要开始的活动 列表
    // 发送消息时间转换
    dayjs.extend(utc)
    dayjs.extend(relativeTime)
    // 切换tab
    const handleTabClick = async (tab: string | null | number) => {
        await setSelectedTab(tab);
        if (tab === 'Activities') {
            activityInfoTodayFn()
        }else{
            chartStore.setHasItBeenRead(true)
            saveLastMessageFn(); // 更新未读消息
        }
    };
    // 拒绝
    const rejectFn = (chatId: any, index: number) => {
        Modal.confirm({
            title: 'Reject',
            content: 'Are you sure you want to reject it.',
            okText: 'Confirm',
            cancelText: 'Cancel',
            centered: true,
            onOk: async () => {
                try {
                    const res = await friendsRefuse({
                        timestamp: new Date().getTime(),
                        chatId
                    })
                    if (res.data.code === 200) {
                        // 更新消息状态
                        const requestSDataList: any[] = chartStore.chartsState['requestS']
                        requestSDataList[index]['hasItBeenRead'] = true
                        requestSDataList[index]['operationType'] = 'Rejected'
                        chartStore.setRequestS(requestSDataList)
                        message.success(res.data.msg)
                        saveLastMessageFn(); // 更新未读消息
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });
    }
    // 同意
    const acceptFn = (chatId: any, index: number) => {
        Modal.confirm({
            title: 'Accept',
            content: 'Do you agree to this request.',
            okText: 'Confirm',
            cancelText: 'Cancel',
            centered: true,
            onOk: async () => {
                try {
                    const res = await friendsAgree({
                        timestamp: new Date().getTime(),
                        chatId
                    })
                    if (res.data.code === 200) {
                        const requestSDataList: any[] = chartStore.chartsState['requestS']
                        requestSDataList[index]['hasItBeenRead'] = true
                        requestSDataList[index]['operationType'] = 'Agreed'
                        chartStore.setRequestS(requestSDataList)
                        message.success(res.data.msg)
                        saveLastMessageFn(); // 更新未读消息
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });
    }
    // 今天要开始的活动
    const activityInfoTodayFn = async () => {
        try {
            const res = await activityInfoToday()
            if (res.data.code === 200) {
                setActivityList(res.data.data)
                console.log(res)
            }
        } catch (error) {
            console.log(error, 'error')
        }
    }
    // 根据ids获取账户信息
    const obtainNameBasedOnIDFn = async () => {
        try {
            const ids = chartStore.chartsState.requestS.map((obj: any) => obj.fromId);
            if (ids && ids.length !== 0) {
                const res = await obtainNameBasedOnID(ids);
                if (res.data.code === 200) {
                    setUserInfoList(res.data.data)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }
    // 根据id获取账户
    const setUserName = (item: any) => {
        const msgIndex = userInfoList.findIndex((_item: any) => Number(_item.userId) === Number(item.fromId));
        if (msgIndex >= 0) {
            return `${userInfoList[msgIndex]['userName']} (${userInfoList[msgIndex]['nickName']})`
        }
    }
    // 在组件挂载后调用
    useEffect(() => {
        setRequestSData([...chartStore.chartsState.requestS])
        obtainNameBasedOnIDFn()
    }, [chartStore.chartsState.requestS]);
    useEffect(() => {
        activityInfoTodayFn()
        HeadNavigation.setItem({ backTo: '/home', goName: 'Home', currentName: 'Notification' });
    }, []);
    return (
        <div className='w-full h-full flex flex-col overflow-hidden custom_scrollbar py-4 px-16 space-y-6'>
            <p className="text-4xl font-medium text-[#232323] min-10">Notification</p>
            <ul className="flex items-center space-x-7 text-xl font-normal text-[#707070]">
                {articleType.map((tab, index) => (
                    <li key={index}
                        onClick={() => handleTabClick(tab.dictCode)}
                        className={`pb-2 flex space-x-2 cursor-pointer border-b-2 duration-500 
                            ${selectedTab === tab.dictCode ? 'border-[#232323] text-[#232323]' : 'border-white'}`}>
                        <span>{tab.dictLabel}</span>
                        <div className='flex items-center justify-center text-base font-medium 
                        text-[#707070] w-9 h-9 bg-[#F1F1F1] rounded-full'>
                            {tab.dictCode === 'Activities' ? activityList?.length || 0 : requestSData?.length || 0}
                        </div>
                    </li>
                ))}
            </ul>
            <div className='w-full flex-1 overflow-auto'>
                {/* 即将开始的活动 */}
                {selectedTab === 'Activities' && activityList.map((item: any, index: number) => (
                    <div key={index} className='flex items-center justify-between border-b border-[#DEDEDE] p-5'>
                        <div className='w-full flex space-x-4 '>
                            <div className='w-12 h-12 flex items-center justify-center bg-[#ccc] rounded-full  '>
                                <img src={require("../../assets/images/eventsActivities/announcement.png")} className=' w-6 h-6' alt='announcement' />
                            </div>
                            <div className='flex-1'>
                                <p className='text-xl font-medium text-[#232323]'>{item.title}</p>
                                <div className='w-full text-lg font-normal text-[#707070] flex items-center space-x-2'>
                                    <div className='u-line-1'>{item.description}</div>
                                    <div className='w-1/2 flex items-center space-x-2'>
                                        <div className='w-1 h-1 rounded-full bg-[#575757]'></div>
                                        <span className='text-[#575757]'>{dayjs(item.startTime).fromNow()}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                {/* 好友请求列表 */}
                {selectedTab === 'FriendRequests' && requestSData.map((item: any, index: number) => (
                    <div key={index} className='flex items-center justify-between border-b border-[#DEDEDE] p-5'>
                        <div className='flex space-x-4 '>
                            <div className='flex w-12 h-12 bg-[#ccc] rounded-full overflow-hidden'>
                                <AvatarImg id={item.fromId}/>
                            </div>
                            <div>
                                <p className=' text-xl font-medium text-[#232323]'>{item.content}</p>
                                <p className=' text-lg font-normal text-[#707070] flex items-center space-x-2'>
                                    <span>{setUserName(item)}</span>
                                    <span className=' w-1 h-1 rounded-full bg-[#575757]'></span>
                                    <span className='text-[#575757]'>{dayjs.utc(item.timestamp).fromNow()}</span>
                                </p>
                            </div>
                        </div>
                        {(!item.hasItBeenRead &&( item.messageType === '4' || item.type==='4')) &&
                            <div className=' flex space-x-5'>
                                <Button shape="round"
                                    style={{ background: 'none', borderColor: '#275576' }}
                                    className='w-full h-11 flex items-center justify-center space-x-2 text-[#275576]'
                                    onClick={() => rejectFn(item.fromId, index)}
                                >
                                    <CloseOutlined />
                                    <span className='text-base font-medium '>Reject</span>
                                </Button>
                                <Button shape="round"
                                    style={{ background: '#275576', borderColor: '#275576' }}
                                    className='w-full h-11 flex items-center justify-center space-x-2 text-white'
                                    onClick={() => acceptFn(item.fromId, index)}
                                >
                                    <CheckOutlined />
                                    <span className='text-base font-medium '>Accept</span>
                                </Button>
                            </div>
                        }
                        {item.hasItBeenRead && <div className='text-base font-medium '>{item.operationType}</div>}
                    </div>
                ))}
            </div>
        </div>
    );
})

export default Notification;

import { Button } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginByEmail } from "../../../api/login"
import VerifyControl from "../VerifyControl"
import userStore from "../../../store/user"
import throttle from '../../../utils/throttle';
type recheckEmailCallback = (isComplete: boolean) => void;

interface recheckEmailProps {
    handleRecheckEmail: recheckEmailCallback;
    accountParameData: any; // 新加入的属性
}
// 第二步拿到验证码进行登录
const SecondStep: React.FC<recheckEmailProps> = ({ handleRecheckEmail, accountParameData }) => {
    // 定义
    const navigate = useNavigate();
    // 标识验证是否完成的状态
    const [isVerificationComplete, setVerificationComplete] = useState(false);
    // 验证码数据
    const [codeS, setCodeS] = useState('');
    // 加载状态
    const [loadings, setLoadings] = useState<boolean>(false);
    // 根据验证码输入的完整性来判断是否进行下一个验证
    const handleVerificationComplete = (isComplete: boolean, codes: any) => {
        //填写完整 保存验证码
        if (isComplete) {
            setCodeS(codes)
        }
        setVerificationComplete(isComplete);
    };
 
    // 验证登录
    const verifyLogin = async () => {
     try {
        setLoadings(true)
        const res = await loginByEmail({
            // 使用传递过来的 accountParameData
            username: accountParameData.username,
            uuid: accountParameData.uuid,
            code: codeS
        })
        setLoadings(false)
        if (res.data.code === 200) {
            // 保存用户token
            userStore.setToken(res.data.token)
            userStore.setUserInfo({userType:res.data.userType})
            // 跳转到首页
            navigate('/');
            handleRecheckEmail(false)
        }
     } catch (error) {
        console.log(error)
        setLoadings(false)
     }
    }
    return (
        <div className='space-y-8 login-min'>
            <div className="space-y-5 text-center px-16">
                <p className=" text-4xl font-medium text-[#232323] min-10">Verify your email address</p>
                <p className=" text-xl font-normal text-[#959595]">Empowering seniors, Enriching lives.</p>
            </div>
            {/* 验证码输入控件 */}
            <VerifyControl onInputComplete={handleVerificationComplete} />
            <div className='w-full space-y-5'>
                <Button shape={'round'}
                    style={{
                        height: '46px', width: '100%', background: isVerificationComplete ? '#275576' : '#F1F1F1',
                        borderColor: isVerificationComplete ? '#275576' : '#F1F1F1',
                        color: isVerificationComplete ? '#ffffff' : '#959595'
                    }}
                    disabled={!isVerificationComplete}
                    onClick={() => throttle(verifyLogin)}
                    loading={loadings}
                >
                    <span className='text-base font-medium '>Verify</span>
                </Button>
                <Button shape={'round'} style={{ height: '46px', width: '100%', borderColor: '#275576' }}
                    onClick={() => handleRecheckEmail(false)} >
                    <span className='text-base font-medium text-[#275576]'>Recheck your email</span>
                </Button>
            </div>
            <p className=' text-base font-medium text-[#232323] text-center'>Sent code will be valid till next 15 minutes</p>

        </div>
    )
}
export default SecondStep
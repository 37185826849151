import { Input, Button, Form, Select, Radio, DatePicker, InputNumber, message } from 'antd';
import type { FormProps } from 'antd';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import UploadFiles from '../../../components/Public/UploadFiles';
import UploadType from '../../../store/UploadType'
import { addActivity, updateActivity } from '../../../api/activity'
import dictStore from '../../../store/dict'
import detailsStore from '../../../store/Details';
import dayjs from 'dayjs'
import HeadNavigation from '../../../store/HeadNavigation';
type FieldType = {
    title: string,
    address: string,
    category: number,
    type: number,
    status: number,
    commentStatus: number,
    bannerPath: string,
    description: string,
    content: string,
    startTime: string,
    endTime: string,
    pcount: number
};

// 上传文件
const Add = () => {
    // 定义
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get('type');
    const [initialValues] = useState<any>(type === 'add' ? {} : detailsStore.Details);
    const [bannerPath, setBannerPath] = useState<string>(initialValues?.bannerPath || '');
    // 文件路径
    const [pathKey] = useState<string>('eventsActivities');
    const [isDraft, setIsDraft] = useState(false); // 用于标记是否保存为草稿
    const [form] = Form.useForm();
    // 加载状态
    const [loadings, setLoadings] = useState<boolean>(false);
    const [loadingsDraft, setLoadingsDraft] = useState<boolean>(false);

    // 保存提交数据数据
    const handleSignupClick: FormProps<FieldType>['onFinish'] = async (values) => {
        if (isDraft) setLoadingsDraft(true)
        else setLoadings(true)
        const param: any = {
            ...values,
            bannerPath,

        }
        try {
            // 定义输出的日期格式
            const outputFormat = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]'; // ISO 8601 格式
            param.startTime = param.startTime.format(outputFormat)
            param.endTime = param.endTime.format(outputFormat)
            param.status = isDraft ? 1 : 4
            const res = type === 'add'
                ? await addActivity(param)
                : await updateActivity({ ...param, id: initialValues.id });

            if (res.data.code === 200) {
                message.success(res.data.msg)
                navigate('/eventsActivities/home');
            }
            if (isDraft) setLoadingsDraft(false)
            else setLoadings(false)
        } catch (error) {
            if (isDraft) setLoadingsDraft(false)
            else setLoadings(false)
            console.error("Error submitting form:", error);
        }

    }
    // 文件上传成功并获取到 signedUrl 图片地址 
    const handleFileUploadSuccess = (url: string) => {
        setBannerPath(url)
    };
    useEffect(() => {
        HeadNavigation.setItem({ backTo: '/eventsActivities/home', goName: 'Events & Activities', currentName: 'Events & Activities' });
    }, []);
    return (
        <Form
            style={{ maxWidth: '100%' }}
            form={form}
            onFinish={handleSignupClick}
            layout="vertical"
            className="w-full h-full flex flex-col py-5 px-14 space-y-7 min-1-1"


        >
            <div className="flex items-center justify-between min-1-1">
                <div className="text-4xl font-medium text-[#232323] min-10">Submit new {UploadType.type} content</div>
                <div className=' flex items-center space-x-5 min-4'>
                    <Button shape="round"
                        style={{ background: '#F1F1F1', borderColor: '#F1F1F1' }}
                        className='h-11  px-4 space-x-2  flex items-center  '
                        onClick={() => navigate(-1)}
                    >
                        <img src={require('../../../assets/images/public/close.png')} alt='close' />
                        <span className='text-base font-medium text-[#275576] '> Cancel</span>
                    </Button>
                    <Button shape="round"
                        style={{ background: 'none', borderColor: '#275576' }}
                        className='h-11  px-4 flex items-center justify-center space-x-2'
                        htmlType="submit"
                        onClick={() => setIsDraft(true)} // 点击时设置为草稿状态
                        loading={loadingsDraft}
                    >
                        <img src={require('../../../assets/images/public/save.png')} alt='save' />
                        <span className=' text-base font-medium text-[#275576] '> Save as draft</span>
                    </Button>
                    <Button shape="round"
                        style={{ background: '#275576', borderColor: '#275576' }}
                        className='h-11  px-4 flex items-center justify-center space-x-2 '
                        htmlType="submit"
                        onClick={() => setIsDraft(false)}
                        loading={loadings}
                    >
                        <img src={require('../../../assets/images/public/send-01.png')} alt='send-02' />
                        <span className=' text-base font-medium text-white '> Submit new content1231</span>
                    </Button>

                </div>
            </div>
            <div className="w-full flex-1 flex space-x-5 overflow-hidden min-1-1">
                <div className='flex-1 border border-[#D2D2D2] rounded-md overflow-auto'>
                    <p className='w-full px-5 text-xl font-medium h-16 leading-[64px] bg-[#F6F8F8]'>Details</p>
                    <div className='p-5 space-y-5'>
                        <div className='w-full flex justify-between space-x-5 min-18'>
                            <Form.Item<FieldType>
                                className='w-full h-14 min-17'
                                name="title"
                                label="Title"
                                initialValue={initialValues.title}
                                rules={[
                                    { required: true, message: 'Please input title' },
                                ]}>
                                <Input placeholder="Please input title."
                                    style={{ height: '3.5rem', width: '100%' }} />
                            </Form.Item>
                            <Form.Item<FieldType>
                                className='w-full h-14 min-17'
                                name="address"
                                label="Address"
                                initialValue={initialValues.address}
                                rules={[
                                    { required: true, message: 'Please input address' },
                                ]}>
                                <Input placeholder="Please input address."
                                    style={{ height: '3.5rem', width: '100%' }} />
                            </Form.Item>
                        </div>
                        <div className='w-full flex justify-between space-x-5 min-18'>
                            <Form.Item<FieldType>
                                className='w-full h-14 min-17'
                                name="category"
                                label="Select categories"
                                initialValue={initialValues.category}
                                rules={[
                                    { required: true, message: 'Please Select categories' },
                                ]}>
                                <Select
                                    style={{ height: '3.5rem', width: '100%' }}
                                    placeholder="Select"
                                    dropdownStyle={{ zIndex: 10002 }}
                                >
                                    {dictStore.state.activity_category.map((item: any) => (
                                        <Select.Option label={item.dictLabel} value={item.dictCode} key={item.dictCode}>
                                            {item.dictLabel}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item<FieldType>
                                className='w-full h-14 min-17'
                                name="type"
                                label="Tags"
                                initialValue={initialValues.type}
                                rules={[
                                    { required: true, message: 'Please Select Tags' },
                                ]}>
                                <Select
                                    style={{ height: '3.5rem', width: '100%' }}
                                    placeholder="Please Select Tags"
                                    dropdownStyle={{ zIndex: 10002 }}
                                >
                                    {dictStore.state.activity_type.map((item: any) => (
                                        <Select.Option label={item.dictLabel} value={item.dictCode} key={item.dictCode}>
                                            {item.dictLabel}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className='w-full flex justify-between space-x-5 min-18'>
                            <Form.Item<FieldType>
                                className='w-full h-14 min-17'
                                name="startTime"
                                label="startTime"
                                initialValue={dayjs(initialValues.startTime)}
                                rules={[
                                    { required: true, message: 'Please Select startTime!' },
                                ]}
                            >
                                <DatePicker showTime className='w-full h-14' />
                            </Form.Item>
                            <Form.Item<FieldType>
                                className='w-full h-14 min-17'
                                name="endTime"
                                label="endTime"
                                initialValue={initialValues.endTime ? dayjs(initialValues.endTime) : ''}
                                rules={[
                                    { required: true, message: 'Please Select endTime!' },
                                ]}
                            >
                                <DatePicker showTime className='w-full h-14' format="YYYY-MM-DD HH:mm:sss" />
                            </Form.Item>
                        </div>
                        <div className='w-full flex justify-between space-x-5 min-18'>
                            <Form.Item<FieldType>
                                name="pcount"
                                label="Attendees"
                                initialValue={initialValues.pcount}
                                rules={[
                                    { required: true, message: 'Please input your attendees!' },
                                ]}
                                className='w-1/2 h-14 min-17-17'
                            >
                                <InputNumber min={1} className='w-full h-14 min-17' />
                            </Form.Item>
                            <Form.Item<FieldType>
                                name="description"
                                label="Summary"
                                initialValue={initialValues.description}
                                rules={[
                                    { required: true, message: 'Please input your description!' },
                                ]}
                                className='w-1/2 h-14 min-17-17'
                            >
                                <Input.TextArea rows={6} placeholder="Write here.." />
                            </Form.Item>
                        </div>


                    </div>
                </div>
                <div className='min-14'>
                    <Form.Item<FieldType>
                        name="bannerPath"
                        initialValue={initialValues.bannerPath}
                        rules={[
                            { required: true, message: 'Please select a file!' },
                        ]}
                    >
                        <div className='w-full h-64  '>
                            <UploadFiles onFileUploadSuccess={handleFileUploadSuccess} keyPrefix={pathKey}>
                                <div className='h-full flex flex-col items-center justify-center'>
                                    <img src={require(`../../../assets/images/public/upload-01.png`)} alt='upload' />
                                    <p className=" text-base font-normal text-[#275576]">Upload image</p>
                                </div>
                                {bannerPath && <img className='w-full h-full object-cover absolute top-0 left-0' src={bannerPath} alt='imgSrc' />}
                            </UploadFiles>
                        </div>
                    </Form.Item>
                    <div className='border-b border-[#D2D2D2] pb-5  '>
                        <p className=' text-xl font-normal text-[#232323]'>Main banner image</p>
                        <div className=' space-y-2 text-lg font-normal text-[#707070]'>
                            <p>Please use JPG or PNG with non-transparent background.</p>
                            <p>Recommended dimensions of 520x240</p>
                        </div>
                    </div>
                    <div className=' py-5 space-y-4'>
                        <p className=' text-lg font-normal text-[#707070]'>Comments settings</p>
                        <Form.Item<FieldType>
                            name="commentStatus"
                            initialValue={initialValues.commentStatus || 1}
                        >
                            <Radio.Group >
                                <Radio value={1} >Visible</Radio>
                                <Radio value={2}>Hidden</Radio>
                                <Radio value={3}>Locked</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className=' flex items-center space-x-5 min-15'>
                    <Button shape="round"
                        style={{ background: '#F1F1F1', borderColor: '#F1F1F1' }}
                        className='h-11  px-4 space-x-2  flex items-center  min-16'
                        onClick={() => navigate(-1)}
                    >
                        <img src={require('../../../assets/images/public/close.png')} alt='close' />
                        <span className='text-base font-medium text-[#275576] '> Cancel</span>
                    </Button>
                    <Button shape="round"
                        style={{ background: 'none', borderColor: '#275576' }}
                        className='h-11  px-4 flex items-center justify-center space-x-2 min-16'
                        htmlType="submit"
                        onClick={() => setIsDraft(true)} // 点击时设置为草稿状态
                        loading={loadingsDraft}
                    >
                        <img src={require('../../../assets/images/public/save.png')} alt='save' />
                        <span className=' text-base font-medium text-[#275576] '> Save as draft</span>
                    </Button>
                    <Button shape="round"
                        style={{ background: '#275576', borderColor: '#275576' }}
                        className='h-11  px-4 flex items-center justify-center space-x-2 min-16'
                        htmlType="submit"
                        onClick={() => setIsDraft(false)}
                        loading={loadings}
                    >
                        <img src={require('../../../assets/images/public/send-01.png')} alt='send-02' />
                        <span className=' text-base font-medium text-white '> Submit new content</span>
                    </Button>

                </div>
                </div>
            </div>
        </Form>
    )
}
export default Add
import React, { useState, useRef, useCallback, useEffect, CSSProperties } from 'react';
import { Input } from 'antd';
type InputCompleteCallback = (isComplete: boolean, code: string) => void;

interface VerifyControlProps {
    onInputComplete: InputCompleteCallback;
}
// 验证码输入控件
const VerifyControl: React.FC<VerifyControlProps> = ({ onInputComplete }) => {
    // 六个验证码输入框
    const [codes, setCodes] = useState(Array.from({ length: 6 }, () => ''));
    const inputsRef = useRef<any>([]);
    // 自定义input输入框样式
    const inputStyle: CSSProperties = { height: '100%', width: '56px', textAlign: 'center',fontSize:'16px',color:'#232323',fontWeight:400 };
    useEffect(() => {
        // 初始化时聚焦第一个输入框
        inputsRef.current[0].focus();
    }, []);
    // 验证码是否完整输入
    useEffect(() => {
        const isComplete = codes.every(code => code !== '');
        if (isComplete) {
            // 将验证码拼接成字符串
            const verificationCode = codes.join('');
            // 传递验证码到父组件 
            onInputComplete(true, verificationCode);
        } else {
            onInputComplete(false, '');
        }
    }, [codes, onInputComplete]);
    // 处理输入框内容变化
    const handleChange = useCallback((index: number, event: any) => {
        // 匹配一个0-9的数字
        const currentValue = event.target.value.match(/[0-9]{1}/)
            ? event.target.value
            : '';

        // 如果输入有效值, 则自动聚焦到下一个输入框
        if (currentValue) {
            inputsRef.current[index + 1]?.focus();
        }
        // 创建一个新的数组来存储更新后的验证码
        setCodes((prevCodes) => {
            const newCodes = [...prevCodes];
            // 将当前位置的验证码更新为新的值
            newCodes[index] = currentValue;
            return newCodes;
        });
    }, []);

    // 处理删除键按下事件
    const handleDelete = useCallback((index: number, event: any) => {
        const { key } = event;

        // 如果不是删除键, 则提前结束
        if (key !== 'Backspace') {
            return;
        }

        // 当前输入框有值, 则删除当前输入框内容
        if (codes[index]) {
            setCodes((prevCodes) => {
                const newCodes = [...prevCodes];
                newCodes[index] = '';
                return newCodes;
            });
        } else if (index > 0) {
            // 当前输入框没有值, 则删除上一个输入框内容, 并且光标聚焦到上一个输入框
            setCodes((prevCodes) => {
                const newCodes = [...prevCodes];
                newCodes[index - 1] = '';
                return newCodes;
            });
            inputsRef.current[index - 1].focus();
        }
    }, [codes]);

    // 处理粘贴事件
    const handlePaste = useCallback((event: any) => {
        const pastedValue = event.clipboardData.getData('Text');
        const pastNum = pastedValue.replace(/[^0-9]/g, '');
        // 重新生成 codes: 6 位, 每一位取剪切板对应位置的数字, 没有则置空
        const newCodes = Array.from(
            { length: 6 },
            (_, index) => pastNum.charAt(index) || '',
        );
        // 修改状态 codes
        setCodes(newCodes);

        // 光标要聚焦的输入框的索引, 这里取 pastNum.length 和 5 的最小值即可, 当索引为 5 就表示最后一个输入框了
        const focusIndex = Math.min(pastNum.length, 5);
        inputsRef.current[focusIndex]?.focus();
    }, []);
    // 输入框聚焦情况下, 自动选中输入框的内容
    const handleOnFocus = useCallback((e: any) => {
        e.target.select();
    }, []);

    return (
        <div className='w-full h-[2.875rem] flex justify-center space-x-5 code-min'>
            {codes.map((value, index) => (
                <Input
                    placeholder=" "
                    key={index}
                    style={inputStyle}
                    value={value}
                    maxLength={1}
                    onPaste={handlePaste}
                    onFocus={handleOnFocus}
                    onKeyDown={(event) => handleDelete(index, event)}
                    onChange={(event) => handleChange(index, event)}
                    ref={(ele) => (inputsRef.current[index] = ele)}
                />
            ))}
        </div>
    );
};

export default VerifyControl;

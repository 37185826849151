import React, { useState } from 'react';
import VerifyControl from '../Login/VerifyControl';
import { Button } from 'antd';
import { register } from '../../api/register'
import userStore from "../../store/user"
import throttle from '../../utils/throttle';
// 定义 prevStep 参数的类型
type nextStepFunction = () => void;
type PrevStepFunction = () => void;

// 使用 PrevStepFunction 类型声明 prevStep 参数
const SecondStep: React.FC<{ nextStep: nextStepFunction, prevStep: PrevStepFunction, userData: any }> = ({ nextStep, prevStep, userData }) => {
    // 标识验证是否完成的状态
    const [isVerificationComplete, setVerificationComplete] = useState(false);
    // 验证码数据
    const [codeS, setCodeS] = useState('');
    // 加载状态
    const [loadings, setLoadings] = useState<boolean>(false);
    // 根据验证码输入的完整性来判断是否进行下一个验证
    const handleVerificationComplete = (isComplete: boolean, codes: any) => {
        // 获取验证码
        if (isComplete) {
            setCodeS(codes)
        }
        setVerificationComplete(isComplete);
    };
    // 验证登录
    const verifyLogin = async () => {
        try {
            const { username, uuid, nickName, firstName, lastName } = userData
            setLoadings(true)
            const res = await register({
                username,
                uuid,
                nickName,
                firstName,
                lastName,
                code: codeS

            })
            setLoadings(false)
            if (res.data.code === 200) {
                // 保存用户token
                userStore.setToken(res.data.token)
                userStore.setUserInfo({ userType: res.data.userType })
                nextStep()
            }
        } catch (error) {
            console.log(error)
            setLoadings(false)
        }
    }
    return (
        <div className='space-y-8 login-min'>
            <div className=" space-y-5 text-center px-16">
                <p className=" text-4xl font-medium text-[#232323] min-10">Verify your email address</p>
                <p className=" text-xl font-normal text-[#959595]">Empowering seniors, Enriching lives.</p>
            </div>
            {/* 验证码输入控件 */}
            <VerifyControl onInputComplete={handleVerificationComplete} />
            <div className='w-full space-y-5'>
                <Button shape={'round'}
                    style={{
                        height: '46px', width: '100%', background: isVerificationComplete ? '#275576' : '#F1F1F1',
                        borderColor: isVerificationComplete ? '#275576' : '#F1F1F1',
                        color: isVerificationComplete ? '#ffffff' : '#959595'
                    }}
                    disabled={!isVerificationComplete}
                    onClick={() => throttle(verifyLogin)}
                    loading={loadings}
                >
                    <span className='text-base font-medium '>Verify</span>
                </Button>
                <Button shape={'round'} style={{ height: '46px', width: '100%', borderColor: '#275576' }}
                    onClick={prevStep} >
                    <span className='text-base font-medium text-[#275576]'>Recheck your email</span>
                </Button>
            </div>
            <p className=' text-base font-medium text-[#232323] text-center'>Sent code will be valid till next 15 minutes</p>
        </div>
    );
}

export default SecondStep;

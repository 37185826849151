import React, { useState } from 'react';
import { Button, Input, message } from 'antd';
import { Link } from 'react-router-dom';
import { getCode } from "../../../api/login"
import throttle from '../../../utils/throttle';
// 类型定义
type emailVerificationData = (data: any, step: boolean) => void;
interface recheckEmailProps {
    emailVerificationData: emailVerificationData;

}
// 第一步获取验证码
const FirstStep: React.FC<recheckEmailProps> = ({ emailVerificationData }) => {
    // 用于存储输入的邮箱或电话号码
    const [email, setEmail] = useState('');
    const [loadings, setLoadings] = useState<boolean>(false);
    // 更新邮箱或电话号码的状态
    const handleInputChange = (e: any) => {
        setEmail(e.target.value);
    };
    // 获取验证码
    const handleGetCode = async () => {
        // 验证邮箱
        const emailOrPhoneRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // 验证邮箱格式是否正确
        if (!emailOrPhoneRegex.test(email)) {
            message.error('Please enter a valid email address');
            return;
        }
        try {
            // 获取验证码请求
            setLoadings(true)
            const res = await getCode({ username: email, type: 'login' })
            if (res.data.code === 200) {
                const { uuid } = res.data || {}
                const data = {
                    username: email,
                    uuid,
                }
                message.success('Verification code has been sent');
                emailVerificationData(data, true)
            }
            setLoadings(false)
        } catch (error) {
            setLoadings(false)
            console.dir(error)
        }
    }

    return (
        <div className='space-y-8 login-min'>
            <div className=" space-y-5 text-center px-16">
                <p className=" text-4xl font-medium text-[#232323] min-10">Sign in to your account</p>
                <p className=" text-xl font-normal text-[#959595]">Empowering seniors, Enriching lives.</p>
            </div>
            <div className='w-full space-y-5'>
                <div className='w-full h-14'>
                    <Input placeholder="Enter your email address or phone number"
                        style={{ height: '100%', width: '100%' }}
                        value={email}
                        onChange={handleInputChange}
                    />
                </div>
                <div className='w-full h-[2.875rem]'>
                    <Button shape={'round'} style={{
                        height: '100%', width: '100%',
                        borderColor: email ? '#275576' : '#D2D2D2',
                        color: email ? '#275576' : '#959595'
                    }}
                        onClick={() => throttle(handleGetCode)}
                        disabled={!email}
                        loading={loadings}
                    >
                        <span className=' text-base font-medium '> Send code</span>
                    </Button>
                </div>
            </div>
            {/* 其他方式登录 */}
            {/* <OtherLoginOrRegist /> */}
            <div className='w-full h-[2.875rem]'>
                <Link to='/register'>
                    <Button shape={'round'} style={{ height: '100%', width: '100%', background: '#275576' }} >
                        <span className='text-base font-medium text-white'>Create an account</span>
                    </Button>
                </Link>
            </div>
        </div>
    )
}
export default FirstStep